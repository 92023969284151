import { useState, useRef, useCallback, useEffect } from "react";
import MainLefttToolbar from "../MainLeftToolbar/MainLeftToolbar";
import ListTollbar from "../MainRightToolbar/MainRightToolbar";
import PopupTree from "../Popup/Popup.jsx";
import Example from "../WikiArticteEditor/WikiArticteEditor";
import Tree from "../MainLeftToolbar/Tree/Tree";
import TreeItemDiagram from "../MainLeftToolbar/Tree/TreeItem/TreeItemDiagram";
import TreeItemFile from "../MainLeftToolbar/Tree/TreeItem/ThreeFileItem";
import TreeItem from "../MainLeftToolbar/Tree/TreeItem/ThreeItem";
import test from "../WikiArticteViever/test.pdf";
import Jwt_proxy from "../../API/Api";
import { Routes, Route, useNavigate } from "react-router-dom";
import SectionMenu from "../Pages/SectionMenu";
import SectionPage from "../Pages/sectionPage/SectionPage";
import FilePage from "../Pages/sectionPage/filePage";
import Draw from "../Pages/drawPage";
import DiagramPage from "../Pages/diagram_page/diagram.page";

import Articlepage from "../Pages/Article";
function MainWidget({ logged, setLogged, Refresh }) {
  const [addToGroup, setAddToGroup] = useState(null);
  const edit = useRef(null);
  let [redact, setRedact] = useState(false);
  function onRedact() {
    setRedact((redact = !redact));
  }
  const [section_id, setSectionId] = useState();

  const [formData, setFormData] = useState({
    id: null,
    pid: "null",
    name: null,
    creation_date: new Date(),
    parent_settings: false,
    group_restrictions: false,
    code: null,
    title_font_color: null,
  });
  const [isRedaction, setIsRedaction] = useState(true);
  let [post, setPost] = useState({});

  let [popupActive, setpopupActive] = useState(false);
  let [filePdf, setFilePdf] = useState(test);

  function onPopupActive(dat) {
    getPopupPathData(dat);
    setpopupActive((popupActive = !popupActive));
  }

  function getPost(i) {
    setPost((post = i));
  }
  let [selected, setSelected] = useState(null);
  const [articleWiev, setArticleWiew] = useState(false);
  const [ArticleId, setArticleId] = useState();
  const [ArticleData, setArticleData] = useState({
    id: null,
    name: null,
    body_type: 2,
    abstruct: null,
    section_id: null,
    wiki_article_type_id: 1,
    body: null,
    create_date: new Date(),
    author: localStorage.getItem("username"),
    code: null,
    title_font_color: null,
  });

  const [articleText, setArticleText] = useState("");

  function addArticleText(e) {
    setArticleText((articleText) => (articleText = e));
    setArticleData((ArticleData) => ({
      ...ArticleData,
      body: e,
    }));
  }
  useEffect(() => {
    Jwt_proxy(getFirstItemForTree);
    Jwt_proxy(getLeftTree);
    if (!popupActive) {
      setFormData({
        id: null,
        pid: "null",
        name: null,
        creation_date: new Date(),
        parent_settings: true,
        group_restrictions: false,
        code: null,
        title_font_color: null,
      });
    }
  }, [popupActive]);
  const [redArticle, setRedArticle] = useState();
  let [sectionWay, getSectionWay] = useState([]);

  let [WayString, setWayString] = useState("");

  function fu(el, s, flag = null) {
    let r = el.parentElement.querySelector(".strong");
    let f =
      r.parentElement.parentElement.parentElement.parentElement.parentElement;
    if (flag !== null) {
      f = r.parentElement.parentElement.parentElement.parentElement;
    }
    let y = f.querySelector(".strong");
    let l = r.innerHTML;
    let str = [...s];
    str.push(l);
    if (!f.classList.contains("TreeGroupItem")) {
      if (!str.includes(parentData.name)) {
        str.push(parentData.name);
      }
      return str;
    } else {
      let st = fu(y, str, flag);

      return st;
    }
  }
  let [save, setSave] = useState(null);
  function GetsechionName(i) {
    if (save !== i.id) {
      setSave((save = i.id));
      setFormData((formData) => ({
        ...formData,
        pid: i.id,
        name: null,
        id: null,
        description: null,
      }));
      let el = document.getElementById(i.id);
      let str = [""];
      str = fu(el, str);
      let string = String([...str.reverse()]).replaceAll(",", "/");
      setWayString(string);
      getSectionWay((sectionWay) => (sectionWay = str));
    } else {
      setSave((save = null));
      setFormData((formData) => ({
        ...formData,
        pid: "00000000-0000-0000-0000-000000000000",
        name: null,
        description: null,
      }));
      let str = [""];
      str = "";
      let string = "";
      setWayString(string);
      getSectionWay((sectionWay) => (sectionWay = str));
    }
  }
  function changeName(e) {
    setFormData((formData) => ({
      ...formData,
      pid: e.pid,
      id: e.id,
      name: e.name,
      parent_settings: e.parent_settings,
      description: e.description,
    }));

    let el = document.getElementById(e.id);
    let str = [""];
    str = fu(el, str);
    if (str.includes(e.name)) {
      delete str[str.indexOf(e.name)];
    }
    let string = String([...str.reverse()]).replaceAll(",", "/");
    setWayString(string);
    getSectionWay((sectionWay) => (sectionWay = str));
  }
  const [pidChanged, setPidChanged] = useState(false);

  function changePid(i) {
    setPidChanged(true);
    setSave((save = i.id));
    setFormData((formData) => ({ ...formData, pid: i.id }));
    let el = document.getElementById(i.id);
    let str = [""];
    str = fu(el, str, true);
    let string = String([...str.reverse()]).replaceAll(",", "/");
    setWayString(string);
    getSectionWay((sectionWay) => (sectionWay = str));
    setTimeout(() => {
      setPidChanged(false);
    }, 1000);
  }

  const [nameAndPathCreate, setNameAndPathCreate] = useState({
    name: null,
    pid: null,
    id: null,
    parent_settings: null,
    isArticle: false,
  });
  const [nameAndPathUpdate, setNameAndPathUpdate] = useState({
    name: null,
    pid: null,
    id: null,
    parent_settings: null,
    isArticle: false,
  });
  function getThreePathData(e, isArticle, type = 0) {
    setNameAndPathCreate((nameAndPathCreate) => ({
      ...nameAndPathCreate,
      id: e.id,
      name: null,
      isArticle: isArticle,
      section_id: e.section_id,
    }));
    setNameAndPathUpdate((nameAndPathUpdate) => ({
      ...nameAndPathUpdate,
      id: e.id,
      name: e.name,
      parent_settings: e.parent_settings,
      pid: e.pid,
      isArticle: isArticle,
      section_id: e.section_id,
      type: type,
    }));
  }
  async function deleteDiagram(data) {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/wiki/diagram/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: data.id }),
    });

    if (res.ok) {
      navigate("/wiki/section/" + data.section_id);
      alert("Диаграма удалена");
      Jwt_proxy(() => getLeftArr(data.section_id));
    } else {
      alert(
        "У вас нет прав на создание/редактирование статей в данном разделе"
      );
    }
  }

  function getPopupPathData(data) {
    if (data.name !== null) {
      changeName(data);
    } else {
      GetsechionName(data);
    }
  }

  const [popupLewelTwooTreeSelected, setPopupLewelTwooTreeSelected] =
    useState();
  const [popupLewelTwooTreeWay, setPopupLewelTwooTreeWay] = useState();
  const [showPopup, setShowPopup] = useState({ show: false, child: null });
  const [showLeftToolbarTree, setShowLeftToolbarTree] = useState([]);
  const [selectedLeftTree, setLeftTree] = useState();
  const [Leftcfg, setLeftcfg] = useState({
    hr: "—",
    articles: true,
    files: true,
    diagrams: true,
    red: getThreePathData,
    search: getThreePathData,
    flagUrl: true,
    left: true,
    ids_list: selectedLeftTree,
  });

  const [parentData, setParentData] = useState({
    name: "M.Reason",
    id: "00000000-0000-0000-0000-000000000000",
    pid: null,
  });

  const [userFolder, setUserFolder] = useState({
    name: localStorage.getItem("username"),
    id: "10000000-0000-0000-0000-000000000000",
    pid: null,
    haveFolderInDb: false,
  });
  async function getFirstItemForTree() {
    let ip =
      localStorage.getItem("server_ip") + "/wiki/section/gen_first_section";
    let token = localStorage.getItem("token");

    let res = await fetch(ip, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
    });

    if (!res.ok) {
      localStorage.setItem("logged", false);
    } else {
      let r = await res.json();
      setParentData(r.first_section);
      if (r.user_section !== null) {
        setUserFolder({
          ...userFolder,
          ...r.user_section,
          haveFolderInDb: true,
        });
        setTreeUserSection({
          ...treeUserSection,
          ...r.user_section,
          haveFolderInDb: true,
        });
      }
    }
  }

  function toggleSelect(id) {
    setNameAndPathUpdate((nameAndPathUpdate) => ({
      ...nameAndPathUpdate,
      id: id,
    }));

    let q = document.querySelectorAll(".TreeWrapper");
    let artcl = q[0].querySelectorAll(".TreeItem");
    let grps = q[0].querySelectorAll(".TreeGroupItemTitle");
    let grps2 = [];
    if (q[1] !== undefined) {
      grps2 = q[1].querySelectorAll(".TreeGroupItemTitle");
    }

    let arr = [...artcl, ...grps, ...grps2];
    let els = [];
    let count = 0;
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].id === id &&
        (arr[i].classList.contains("TreeGroupItemTitle") ||
          arr[i].classList.contains("TreeItem"))
      ) {
        els.push(arr[i]);
        count++;
      } else {
        arr[i].classList.remove("select");
      }
    }
    for (let i = 0; i < els.length; i++) {
      if (count > 0) {
        if (count > 1) {
          els[i].classList.add("select");
        } else {
          els[i].classList.add("select");
        }
      }
    }
  }
  async function getHist(Id) {
    let token = localStorage.getItem("token");
    let histres = await fetch(
      localStorage.getItem("server_ip") + "/api/wiki/get_article_history",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify({ id: Id }),
      }
    );
    if (histres.ok) {
      let h = await histres.json();
      setArticleHistory(h);
    }
  }

  async function getHistFile(Id) {
    let token = localStorage.getItem("token");
    let histres = await fetch(
      localStorage.getItem("server_ip") + "/api/wiki/get_file_history",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify({ id: Id }),
      }
    );
    if (histres.ok) {
      let h = await histres.json();
      setArticleHistory(h);
    }
  }

  async function getHistory_diagram(Id) {
    let token = localStorage.getItem("token");
    let histres = await fetch(
      localStorage.getItem("server_ip") + "/api/wiki/diagram/history",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify({ id: Id }),
      }
    );
    if (histres.ok) {
      let h = await histres.json();
      setArticleHistory(h);
    }
  }

  const [firstArticleLoad, setFirstArticleLoad] = useState(true);
  const [openTree, setopentree] = useState([]);
  async function getLeftArr(id, artId = undefined, hist = undefined) {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/get_way";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: id }),
    });
    if (res.ok) {
      let r = await res.json();
      let art;
      if (artId !== undefined) {
        art = artId;
      }
      if (id !== parentData.id) {
        if (!r.includes(parentData.id)) {
          Jwt_proxy(getFirstItemForTree);
          setUserFolder((userFolder) => ({
            ...userFolder,
            ids_list: r,
            article_id: art,
            haveFolderInDb: true,
          }));
        } else {
          getOpenLeftTree(id, r, art, hist);
        }
      }
    } else if (res.status === 403) {
      getLeftTree();
      goWiki();
    }
  }

  let context = true;

  function getParentId(i) {
    setPidChanged(true);
    setArticleData((ArticleData) => ({
      ...ArticleData,
      section_id: i,
    }));
    setTimeout(() => {
      setPidChanged(false);
    }, 1000);
  }
  const [seectionSectionsPageList, setSeectionSectionsPageList] = useState([]);
  const [seectionArticlesPageList, setSeectionArticlesPageList] = useState([]);
  const [seectionDiagramsPageList, setSeectionDiagramsPageList] = useState([]);
  const [seectionFilesPageList, setSeectionFilesPageList] = useState([]);
  const [secundefined, setSecUndefined] = useState(false);
  function getLeftTree() {
    Jwt_proxy(getFirstItemForTree);
    Jwt_proxy(() =>
      getSections(
        "00000000-0000-0000-0000-000000000000",
        setShowLeftToolbarTree,
        Leftcfg,
        null,
        true
      )
    );
  }
  let cfgg;
const [usrFoldTreeItem, setUsrFoldTreeItem]=useState(null)
  cfgg = {
    hr: "",
    red: changePid,
    search: changePid,
    showPopup: showPopup,
    setShowPopup: setShowPopup,
  };
  async function getLeftArrPopup(id) {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/get_way";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: id }),
    });
    if (res.ok) {
      let r = await res.json();
      if (id !== "00000000-0000-0000-0000-000000000000") {
        if (r.includes("00000000-0000-0000-0000-000000000000")) {
          getTreeFromPopup(id, r);
        } else {
          getTreeFromPopup();
          setTreeUserSection((treeUserSection) => ({
            ...treeUserSection,
            ids_list: r,
            haveFolderInDb: true,
          }));
          setUsrFoldTreeItem(<Tree
            setHistoryArticleData={
              setHistoryArticleData
            }
            firstArticleLoad={firstArticleLoad}
            setArticleHistory={setArticleHistory}
            article_id={treeUserSection.article_id}
            ids_list={r}
            setFirstArticleLoad={setFirstArticleLoad}
            setSeectionArticlesPageList={
              setSeectionArticlesPageList
            }
            setHistoryPopup={setHistoryPopup}
            getHist={getHist}
            toggleSelectArticle={toggleSelectArticle}
            getLeftArr={getLeftArr}
            setSecUndefined={setSecUndefined}
            toggleSelect={toggleSelect}
            setNameAndPathUpdate={setNameAndPathUpdate}
            nameAndPathUpdate={nameAndPathUpdate}
            getThreePathData={getThreePathData}
            parentData={parentData}
            setParentData={setParentData}
            setArticleData={setArticleData}
            ArticleData={ArticleData}
            setArticleWiew={setArticleWiew}
            getSections={getSections}
            selected={selected}
            setSelected={setSelected}
            getParentId={getParentId}
            cfg={cfgg}
            data={{
            ...treeUserSection,
            ids_list: r,
            haveFolderInDb: true,
          }}
            have_user_section={true}
            is_user_folder={true}
          />)
          
        }
       
        
      }
    }
  }
  function getTreeFromPopup(id, r) {
    getSections(
      "00000000-0000-0000-0000-000000000000",
      setPopupLewelTwooTreeSelected,
      cfgg,
      null,
      true,
      r
    );
  }
  const [treeUserSection, setTreeUserSection] = useState({
    name: localStorage.getItem("username"),
    id: "10000000-0000-0000-0000-000000000000",
    pid: null,
    haveFolderInDb: false,
  });
  function getOpenLeftTree(
    id,
    arr = undefined,
    art = undefined,
    hist = undefined
  ) {
    if (arr !== undefined) {
      Jwt_proxy(getFirstItemForTree);
      Jwt_proxy(() =>
        getSections(
          "00000000-0000-0000-0000-000000000000",
          setShowLeftToolbarTree,
          Leftcfg,
          null,
          true,
          arr,
          art,
          hist
        )
      );
    }
  }
  function BodyTypeCheck(dat) {
    return dat.body_type;
  }
  let navigate = useNavigate();
  const goWiki = () => {
    navigate("/wiki");
  };
  const GoArticleToRedact = async (id, body = null) => {
    let token = localStorage.getItem("token");
    let res = await fetch(
      localStorage.getItem("server_ip") + "/api/wiki/article/redact",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify({ id: id }),
      }
    );
    if (res.ok) {
      res = await res.json();
      setArticleData(res);

      if (body !== null) {
        body(BodyTypeCheck(res));
      }
      setArticleText(res["body"]);
    } else if (res.status === 401) {
      alert("отказано в доступе");
      goWiki();
    }
  };
  function toggleSelectArticle(id) {
    let q = document.querySelector(".TreeWrapper");
    let artcl = q.querySelectorAll(".TreeItem");
    let grps = q.querySelectorAll(".TreeGroupItemTitle");
    let arr = [...artcl, ...grps];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === id) {
        arr[i].classList.add("select");
      } else {
        arr[i].classList.remove("select");
      }
    }
  }
  const [itemisfirst, setitemisfirst] = useState(null);
  const getSections = async (
    sectionId,
    setShowHTMLData,
    cfg,
    setArticles = null,
    left = false,
    ids_list = undefined,
    art = undefined,
    hist = undefined,
    setFilesData = undefined,
    setDiagramsData = undefined
  ) => {
    let ip = localStorage.getItem("server_ip") + "/wiki/section/gen_children";
    let token = localStorage.getItem("token");
    if (ids_list !== undefined) {
      ids_list.map((i) => i !== parentData.id);
    }
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({
        pid: ids_list === undefined ? sectionId : ids_list[ids_list.length - 1],
      }),
    });
    if (res.status === 403) {
      alert("секция не найдена");
    } else if (res.ok) {
      let r = await res.json();
      if (left === true) {
        setSeectionSectionsPageList(r.sections_for_page);
        setSeectionArticlesPageList(r.articles_for_page);
        setSeectionDiagramsPageList(r.diagrams);
        setSeectionFilesPageList(r.files);
        setitemisfirst(r.tirstItem);
      }

      let array;
      let selid;
      if (ids_list !== undefined && ids_list.length > 0) {
        array = ids_list;
        delete array[ids_list.indexOf("00000000-0000-0000-0000-000000000000")];
      }
      let tree = [
        ...r.sections.map((i, n) => (
          <Tree
            setStowContext={setStowContext}
            coords={coords}
            setCoords={setCoords}
            context={context}
            deleteBtn={deleteBtn}
            createBtnArt={createBtnArt}
            redactBtn={redactBtn}
            createSecBtn={createSecBtn}
            selid={selid}
            hist={hist}
            firstArticleLoad={firstArticleLoad}
            setFirstArticleLoad={setFirstArticleLoad}
            setSeectionArticlesPageList={setSeectionArticlesPageList}
            setSeectionSectionsPageList={setSeectionSectionsPageList}
            setHistoryPopup={setHistoryPopup}
            setHistoryArticleData={setHistoryArticleData}
            getHist={getHist}
            toggleSelectArticle={toggleSelectArticle}
            getLeftArr={getLeftArr}
            article_id={art}
            setArticleHistory={setArticleHistory}
            setSecUndefined={setSecUndefined}
            ids_list={array}
            toggleSelect={toggleSelect}
            setNameAndPathUpdate={setNameAndPathUpdate}
            nameAndPathUpdate={nameAndPathUpdate}
            getThreePathData={getThreePathData}
            parentData={parentData}
            setParentData={setParentData}
            setArticleData={setArticleData}
            ArticleData={ArticleData}
            setArticleWiew={setArticleWiew}
            getSections={getSections}
            selected={selected}
            setSelected={setSelected}
            cfg={cfg}
            getParentId={getParentId}
            data={i}
          />
        )),
      ];

      setShowHTMLData(tree);

      if (setArticles !== null) {
        r.articles = [
          ...r.articles.map((i, n) => (
            <TreeItem
              setCoords={setCoords}
              coords={coords}
              context={context}
              setStowContext={setStowContext}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              getParentId={getParentId}
              getThreePathData={getThreePathData}
              setHistoryPopup={setHistoryPopup}
              getHist={getHist}
              setArticleHistory={setArticleHistory}
              toggleSelectArticle={toggleSelectArticle}
              ArticleId={ArticleId}
              article_id={art}
              setArticleId={setArticleId}
              setArticleWiew={setArticleWiew}
              data={i}
              key={i.id}
              ArticleData={ArticleData}
              setArticleData={setArticleData}
              setFilePdf={setFilePdf}
              setArticleText={setArticleText}
              selected={selected}
              setSelected={setSelected}
              sectionWay={sectionWay}
              getSectionWay={getSectionWay}
              WayString={WayString}
              setWayString={setWayString}
              setHistoryArticleData={setHistoryArticleData}
            />
          )),
        ];
        setArticles(r.articles);
      }
      if (setFilesData !== undefined) {
        r.files = [
          ...r.files.map((i) => (
            <TreeItemFile
              setCoords={setCoords}
              data={i}
              coords={coords}
              context={context}
              setStowContext={setStowContext}
              getParentId={getParentId}
              getHistFile={getHistFile}
              toggleSelectArticle={toggleSelectArticle}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              getThreePathData={getThreePathData}
            />
          )),
        ];
        setFilesData(r.files);
      }
      if (setDiagramsData !== undefined) {
        r.diagrams = [
          ...r.diagrams.map((i) => (
            <TreeItemDiagram
              setCoords={setCoords}
              coords={coords}
              context={context}
              setStowContext={setStowContext}
              getThreePathData={getThreePathData}
              data={i}
              getParentId={getParentId}
              getHistFile={getHistFile}
              toggleSelectArticle={toggleSelectArticle}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
            />
          )),
        ];
        setDiagramsData(r.diagrams);
      }
    } else {
    }
  };
  function toggleEdit(bool) {
    seteditOpen(bool);
  }
  const [historyArticleData, setHistoryArticleData] = useState(null);
  const [editOpen, seteditOpen] = useState(false);
  const [articleHistory, setArticleHistory] = useState([]);
  const [itsArticleHistory, setItsarticleHistory] = useState(false);
  const [historyPopup, setHistoryPopup] = useState(false);

  async function deleteArticle(data) {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/article/delete";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: data.id }),
    });

    if (res.ok) {
      navigate("/wiki/section/" + data.section_id);
      alert("Статья удалена");
      Jwt_proxy(() => getLeftArr(data.section_id));
    } else {
      alert(
        "У вас нет прав на создание/редактирование статей в данном разделе"
      );
    }
  }
  const [LeftMenuShow, setLeftMenuShow] = useState(false);
  const [RigtMenuShow, setRightMenuShow] = useState(false);

  function toggle_width_article(setE, e) {
    setE(!e);
  }

  async function deleteFile(data) {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/wiki/file/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: data.id }),
    });

    if (res.ok) {
      navigate("/wiki/section/" + data.section_id);
      alert("Файл удален");
      Jwt_proxy(() => getLeftArr(data.section_id));
    } else {
      alert(
        "У вас нет прав на создание/редактирование статей в данном разделе"
      );
    }
  }
  function setTreeFromPopup() {
    Jwt_proxy(() => getLeftArrPopup(nameAndPathUpdate.id));
  }
  function crt() {
    setRedArticle(false);
    setArticleData((ArticleData) => ({
      ...ArticleData,
      id: null,
      name: null,
      create_date: new Date(),
      author: localStorage.getItem("username"),
    }));
    setArticleText("");
    addArticleText("");
  }

  async function deleteSection() {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/wiki/section/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: nameAndPathUpdate.id }),
    });

    if (res.ok) {
      navigate("/wiki");
      alert("раздел удален");
      Jwt_proxy(() => getLeftArr(nameAndPathUpdate.pid));
    } else {
      alert("У вас нет прав на удаление данного раздела");
    }
  }
  const gocreate = () => {
    setRedArticle(false);
    setArticleData((ArticleData) => ({
      ...ArticleData,
      id: null,
      name: null,
      create_date: new Date(),
      author: localStorage.getItem("username"),
    }));
    setArticleText("");
    navigate("article/create");
  };
  function redactS() {
    if (nameAndPathUpdate.type === 1) {
      Jwt_proxy(() => GoArticleToRedact(nameAndPathUpdate.id));
      navigate("/wiki/article/update/" + nameAndPathUpdate.id);
    } else if (nameAndPathUpdate.type === 3) {
      setArticleData((ArticleData) => ({ ...ArticleData, body_type: 4 }));
      navigate("/wiki/article/update/" + nameAndPathUpdate.id);
    } else if (nameAndPathUpdate.type === 2) {
      setArticleData((ArticleData) => ({ ...ArticleData, body_type: 3 }));
      navigate("/wiki/file/update/" + nameAndPathUpdate.id);
    }
  }

  function redactBtn() {
    if (
      formData.id !== null ||
      ArticleData.id !== null ||
      ArticleData.body_type !== 1
    ) {
      if (!nameAndPathUpdate.isArticle) {
        setIsRedaction(true);
        onPopupActive(nameAndPathUpdate);
        getFirstItemForTree();
        setTreeFromPopup();
      } else {
        {
          setArticleData((ArticleData) => ({
            ...ArticleData,
            abstruct: null,
            wiki_article_type_id: 1,
            create_date: new Date(),
            code: null,
            title_font_color: null,
          }));
          redactS();
          setRedArticle(true);
          onRedact();
        }
      }
    }
  }

  function createBtnArt() {
    setArticleData((ArticleData) => ({
      ...ArticleData,
      abstruct: null,
      wiki_article_type_id: 1,
      create_date: new Date(),
      code: null,
      title_font_color: null,
    }));
    crt();
    onRedact();
    gocreate();
  }

  function createSecBtn() {
    setIsRedaction(false);
    onPopupActive(nameAndPathCreate);
    getFirstItemForTree();
    setTreeFromPopup();
  }
  const [coords, setCoords] = useState([]);
  function deleteBtn() {
    if (nameAndPathUpdate.type === 0) {
      Jwt_proxy(deleteSection);
    } else if (nameAndPathUpdate.type === 1) {
      Jwt_proxy(() => deleteArticle(nameAndPathUpdate));
    } else if (nameAndPathUpdate.type === 3) {
      Jwt_proxy(() => deleteDiagram(nameAndPathUpdate));
    } else {
      Jwt_proxy(() => deleteFile(nameAndPathUpdate));
    }
  }
  const [showContext, setStowContext] = useState(false);
  return (
    <div className="contentFlexWrapper">
      <MainLefttToolbar
        coords={coords}
        showContext={showContext}
        setStowContext={setStowContext}
        redactBtn={redactBtn}
        deleteBtn={deleteBtn}
        createSecBtn={createSecBtn}
        createBtnArt={createBtnArt}
        context={context}
        firstArticleLoad={firstArticleLoad}
        ArticleData={ArticleData}
        setSelected={setSelected}
        deleteFile={deleteFile}
        deleteDiagram={deleteDiagram}
        deleteArticle={deleteArticle}
        toggle_width_article={toggle_width_article}
        LeftMenuShow={LeftMenuShow}
        setLeftMenuShow={setLeftMenuShow}
        GoArticleToRedact={GoArticleToRedact}
        getLeftArr={getLeftArr}
        setitemisfirst={setitemisfirst}
        setArticleHistory={setArticleHistory}
        addArticleText={addArticleText}
        toggleEdit={toggleEdit}
        setItsarticleHistory={setItsarticleHistory}
        toggleSelect={toggleSelect}
        getFirstItemForTree={getFirstItemForTree}
        changePid={changePid}
        getPopupPathData={getPopupPathData}
        nameAndPathUpdate={nameAndPathUpdate}
        nameAndPathCreate={nameAndPathCreate}
        getLeftTree={getLeftTree}
        setSeectionSectionsPageList={setSeectionSectionsPageList}
        setSeectionArticlesPageList={setSeectionArticlesPageList}
        seectionArticlesPageList={seectionArticlesPageList}
        seectionSectionsPageList={seectionSectionsPageList}
        showLeftToolbarTree={showLeftToolbarTree}
        setShowPopup={setShowPopup}
        showPopup={showPopup}
        isRedaction={isRedaction}
        popupLewelTwooTreeSelected={popupLewelTwooTreeSelected}
        popupLewelTwooTreeWay={popupLewelTwooTreeWay}
        setPopupLewelTwooTreeWay={setPopupLewelTwooTreeWay}
        setPopupLewelTwooTreeSelected={setPopupLewelTwooTreeSelected}
        GetsechionName={GetsechionName}
        WayString={WayString}
        setWayString={setWayString}
        formData={formData}
        setFormData={setFormData}
        getThreePathData={getThreePathData}
        changeName={changeName}
        getSectionWay={getSectionWay}
        sectionWay={sectionWay}
        setArticleText={setArticleText}
        setArticleData={setArticleData}
        setIsRedaction={setIsRedaction}
        getSections={getSections}
        getPost={getPost}
        onPopupActive={onPopupActive}
        onRedact={onRedact}
        redArticle={redArticle}
        setRedArticle={setRedArticle}
        parentData={parentData}
        setFirstArticleLoad={setFirstArticleLoad}
        setHistoryPopup={setHistoryPopup}
        setHistoryArticleData={setHistoryArticleData}
        getHist={getHist}
        toggleSelectArticle={toggleSelectArticle}
        setSecUndefined={setSecUndefined}
        setNameAndPathUpdate={setNameAndPathUpdate}
        setParentData={setParentData}
        setArticleWiew={setArticleWiew}
        selected={selected}
        cfg={Leftcfg}
        getParentId={getParentId}
        userFolder={userFolder}
      />

      <Routes>
        <Route
          path="article/:Id"
          element={
            <Articlepage
              getLeftTree={getLeftTree}
              RigtMenuShow={RigtMenuShow}
              setRightMenuShow={setRightMenuShow}
              LeftMenuShow={LeftMenuShow}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              setPidChanged={setPidChanged}
              pidChanged={pidChanged}
              red={false}
              expanded_toolbar={false}
              seteditOpen={seteditOpen}
              getSections={getSections}
              redArticle={redArticle}
              ArticleData={ArticleData}
              getLeftArr={getLeftArr}
              setArticleData={setArticleData}
              editOpen={editOpen}
              itsArticleHistory={false}
              setRedArticle={setRedArticle}
              onRedact={onRedact}
              GoArticleToRedact={GoArticleToRedact}
              ishistory={false}
              setHistoryArticleData={setHistoryArticleData}
              setArticleHistory={setArticleHistory}
              toggleSelectArticle={toggleSelectArticle}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              sectionWay={sectionWay}
              WayString={WayString}
              ArticleId={ArticleId}
              filePdf={filePdf}
              articleText={articleText}
              setArticleText={setArticleText}
              setLogged={setLogged}
              setFilePdf={setFilePdf}
            />
          }
        />

        <Route
          path="diagram/:id"
          element={
            <DiagramPage
              ArticleData={ArticleData}
              setArticleData={setArticleData}
              ishistory={false}
              getLeftArr={getLeftArr}
              setFirstArticleLoad={setFirstArticleLoad}
              firstArticleLoad={firstArticleLoad}
              LeftMenuShow={LeftMenuShow}
              seteditOpen={seteditOpen}
              getHistory_diagram={getHistory_diagram}
              RigtMenuShow={RigtMenuShow}
              setRightMenuShow={setRightMenuShow}
            />
          }
        />
        <Route
          path="history/diagram/:id/:date"
          element={
            <DiagramPage
              ArticleData={ArticleData}
              setArticleData={setArticleData}
              ishistory={true}
              getLeftArr={getLeftArr}
              setFirstArticleLoad={setFirstArticleLoad}
              firstArticleLoad={firstArticleLoad}
              LeftMenuShow={LeftMenuShow}
              seteditOpen={seteditOpen}
              getHistory_diagram={getHistory_diagram}
              RigtMenuShow={RigtMenuShow}
              setRightMenuShow={setRightMenuShow}
            />
          }
        />
        <Route
          path="history/article/:Id/:date"
          element={
            <Articlepage
              getLeftTree={getLeftTree}
              RigtMenuShow={RigtMenuShow}
              setRightMenuShow={setRightMenuShow}
              LeftMenuShow={LeftMenuShow}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              setPidChanged={setPidChanged}
              pidChanged={pidChanged}
              red={false}
              expanded_toolbar={false}
              seteditOpen={seteditOpen}
              itsArticleHistory={itsArticleHistory}
              ishistory={true}
              setHistoryArticleData={setHistoryArticleData}
              historyArticleData={historyArticleData}
              setArticleHistory={setArticleHistory}
              toggleSelectArticle={toggleSelectArticle}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              getLeftArr={getLeftArr}
              sectionWay={sectionWay}
              WayString={WayString}
              ArticleData={ArticleData}
              ArticleId={ArticleId}
              filePdf={filePdf}
              articleText={articleText}
              setArticleText={setArticleText}
              setArticleData={setArticleData}
              setLogged={setLogged}
              setFilePdf={setFilePdf}
            />
          }
        />
        <Route
          path="section/:Section_Id"
          element={
            <SectionMenu
              getHist={getHist}
              setFirstArticleLoad={setFirstArticleLoad}
              parentData={parentData}
              setitemisfirst={setitemisfirst}
              setArticleHistory={setArticleHistory}
              toggleSelect={toggleSelect}
              setSeectionSectionsPageList={setSeectionSectionsPageList}
              setSeectionArticlesPageList={setSeectionArticlesPageList}
              secundefined={secundefined}
              nameAndPathCreate={nameAndPathCreate}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              getLeftTree={getLeftTree}
              Leftcfg={Leftcfg}
              getLeftArr={getLeftArr}
              setLeftcfg={setLeftcfg}
              setLeftTree={setLeftTree}
              setSectionId={setSectionId}
            />
          }
        />
        <Route
          path="file/:id"
          element={
            <FilePage
              seteditOpen={seteditOpen}
              getHistFile={getHistFile}
              parentData={parentData}
              setitemisfirst={setitemisfirst}
              setArticleHistory={setArticleHistory}
              toggleSelect={toggleSelect}
              setSeectionSectionsPageList={setSeectionSectionsPageList}
              setSeectionArticlesPageList={setSeectionArticlesPageList}
              secundefined={secundefined}
              nameAndPathCreate={nameAndPathCreate}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              getLeftTree={getLeftTree}
              Leftcfg={Leftcfg}
              getLeftArr={getLeftArr}
              setLeftcfg={setLeftcfg}
              setLeftTree={setLeftTree}
              setSectionId={setSectionId}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              itemisfirst={itemisfirst}
              getThreePathData={getThreePathData}
            />
          }
        />
        <Route
          path="history/file/:id/:date"
          element={
            <FilePage
              seteditOpen={seteditOpen}
              getHistFile={getHistFile}
              parentData={parentData}
              setitemisfirst={setitemisfirst}
              setArticleHistory={setArticleHistory}
              toggleSelect={toggleSelect}
              setSeectionSectionsPageList={setSeectionSectionsPageList}
              setSeectionArticlesPageList={setSeectionArticlesPageList}
              secundefined={secundefined}
              nameAndPathCreate={nameAndPathCreate}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              getLeftTree={getLeftTree}
              Leftcfg={Leftcfg}
              getLeftArr={getLeftArr}
              setLeftcfg={setLeftcfg}
              setLeftTree={setLeftTree}
              setSectionId={setSectionId}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              itemisfirst={itemisfirst}
              getThreePathData={getThreePathData}
            />
          }
        />
        <Route
          path={"article/update/:Id"}
          element={
            <Example
              setHistoryArticleData={setHistoryArticleData}
              treeUserSection={treeUserSection}
              setTreeUserSection={setTreeUserSection}
              nameAndPathCreate={nameAndPathCreate}
              seteditOpen={seteditOpen}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              setPidChanged={setPidChanged}
              pidChanged={pidChanged}
              red={true}
              toggleSelectArticle={toggleSelectArticle}
              expanded_toolbar={true}
              getSections={getSections}
              redArticle={true}
              ArticleData={ArticleData}
              getLeftArr={getLeftArr}
              setArticleData={setArticleData}
              editOpen={editOpen}
              itsArticleHistory={itsArticleHistory}
              setRedArticle={setRedArticle}
              onRedact={onRedact}
              GoArticleToRedact={GoArticleToRedact}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              BodyTypeCheck={BodyTypeCheck}
              setArticleText={setArticleText}
              addArticleText={addArticleText}
              articleText={articleText}
              setLogged={setLogged}
              edit={edit}
              setArticleHistory={setArticleHistory}
              setSeectionArticlesPageList={setSeectionArticlesPageList}
              setHistoryPopup={setHistoryPopup}
              getHist={getHist}
              setSecUndefined={setSecUndefined}
              toggleSelect={toggleSelect}
              setNameAndPathUpdate={setNameAndPathUpdate}
              nameAndPathUpdate={nameAndPathUpdate}
              getThreePathData={getThreePathData}
              parentData={parentData}
              setParentData={setParentData}
              setArticleWiew={setArticleWiew}
              selected={selected}
              setSelected={setSelected}
              getParentId={getParentId}
            />
          }
        />
        <Route
          path={"file/update/:file_id"}
          element={
            <Example
              setHistoryArticleData={setHistoryArticleData}
              treeUserSection={treeUserSection}
              setTreeUserSection={setTreeUserSection}
              nameAndPathCreate={nameAndPathCreate}
              seteditOpen={seteditOpen}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              setPidChanged={setPidChanged}
              pidChanged={pidChanged}
              red={true}
              toggleSelectArticle={toggleSelectArticle}
              expanded_toolbar={true}
              getSections={getSections}
              redArticle={true}
              ArticleData={ArticleData}
              getLeftArr={getLeftArr}
              setArticleData={setArticleData}
              editOpen={editOpen}
              itsArticleHistory={itsArticleHistory}
              setRedArticle={setRedArticle}
              onRedact={onRedact}
              GoArticleToRedact={GoArticleToRedact}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              BodyTypeCheck={BodyTypeCheck}
              setArticleText={setArticleText}
              addArticleText={addArticleText}
              articleText={articleText}
              setLogged={setLogged}
              edit={edit}
              setArticleHistory={setArticleHistory}
              setSeectionArticlesPageList={setSeectionArticlesPageList}
              setHistoryPopup={setHistoryPopup}
              getHist={getHist}
              setSecUndefined={setSecUndefined}
              toggleSelect={toggleSelect}
              setNameAndPathUpdate={setNameAndPathUpdate}
              nameAndPathUpdate={nameAndPathUpdate}
              getThreePathData={getThreePathData}
              parentData={parentData}
              setParentData={setParentData}
              setArticleWiew={setArticleWiew}
              selected={selected}
              setSelected={setSelected}
              getParentId={getParentId}
            />
          }
        />
        <Route
          path={"article/create"}
          element={
            <Example
              setHistoryArticleData={setHistoryArticleData}
              nameAndPathCreate={nameAndPathCreate}
              seteditOpen={seteditOpen}
              firstArticleLoad={firstArticleLoad}
              setFirstArticleLoad={setFirstArticleLoad}
              setPidChanged={setPidChanged}
              red={true}
              toggleSelectArticle={toggleSelectArticle}
              pidChanged={pidChanged}
              expanded_toolbar={true}
              getSections={getSections}
              redArticle={false}
              ArticleData={ArticleData}
              getLeftArr={getLeftArr}
              setArticleData={setArticleData}
              editOpen={editOpen}
              itsArticleHistory={itsArticleHistory}
              setRedArticle={setRedArticle}
              onRedact={onRedact}
              GoArticleToRedact={GoArticleToRedact}
              toggleEdit={toggleEdit}
              setItsarticleHistory={setItsarticleHistory}
              BodyTypeCheck={BodyTypeCheck}
              setArticleText={setArticleText}
              addArticleText={addArticleText}
              articleText={articleText}
              setLogged={setLogged}
              setArticleHistory={setArticleHistory}
              setSeectionArticlesPageList={setSeectionArticlesPageList}
              setHistoryPopup={setHistoryPopup}
              getHist={getHist}
              setSecUndefined={setSecUndefined}
              toggleSelect={toggleSelect}
              setNameAndPathUpdate={setNameAndPathUpdate}
              nameAndPathUpdate={nameAndPathUpdate}
              getThreePathData={getThreePathData}
              parentData={parentData}
              setParentData={setParentData}
              setArticleWiew={setArticleWiew}
              selected={selected}
              setSelected={setSelected}
              getParentId={getParentId}
              treeUserSection={treeUserSection}
              setTreeUserSection={setTreeUserSection}
            />
          }
        />
      </Routes>

      {popupActive ? (
        <PopupTree
        usrFoldTreeItem={usrFoldTreeItem}
          pidChanged={pidChanged}
          openTree={openTree}
          nameAndPathUpdate={nameAndPathUpdate}
          getLeftArr={getLeftArr}
          setItsarticleHistory={setItsarticleHistory}
          historyPopup={historyPopup}
          setHistoryPopup={setHistoryPopup}
          changePid={changePid}
          parentData={parentData}
          getThreePathData={getThreePathData}
          toggleSelect={toggleSelect}
          getFirstItemForTree={getFirstItemForTree}
          getLeftTree={getLeftTree}
          setShowPopup={setShowPopup}
          showPopup={showPopup}
          popupLewelTwooTreeSelected={popupLewelTwooTreeSelected}
          popupLewelTwooTreeWay={popupLewelTwooTreeWay}
          GetsechionName={GetsechionName}
          WayString={WayString}
          setWayString={setWayString}
          formData={formData}
          setFormData={setFormData}
          changeName={changeName}
          getSectionWay={getSectionWay}
          sectionWay={sectionWay}
          isRedaction={isRedaction}
          setIsRedaction={setIsRedaction}
          getSections={getSections}
          addToGroup={addToGroup}
          setAddToGroup={setAddToGroup}
          popupActive={popupActive}
          setpopupActive={setpopupActive}
          setArticleHistory={setArticleHistory}
          setSeectionArticlesPageList={setSeectionArticlesPageList}
          getHist={getHist}
          setSecUndefined={setSecUndefined}
          setNameAndPathUpdate={setNameAndPathUpdate}
          setParentData={setParentData}
          setArticleWiew={setArticleWiew}
          selected={selected}
          setSelected={setSelected}
          getParentId={getParentId}
          treeUserSection={treeUserSection}
          setTreeUserSection={setTreeUserSection}
          setHistoryArticleData={setHistoryArticleData}
          firstArticleLoad={firstArticleLoad}
          setFirstArticleLoad={setFirstArticleLoad}
          toggleSelectArticle={toggleSelectArticle}
          setArticleData={setArticleData}
          ArticleData={ArticleData}
          cfg={cfgg}
        />
      ) : null}
      {editOpen === false ? (
        <SectionPage
          firstArticleLoad={firstArticleLoad}
          setFirstArticleLoad={setFirstArticleLoad}
          itemisfirst={itemisfirst}
          getLeftArr={getLeftArr}
          toggleSelect={toggleSelect}
          getThreePathData={getThreePathData}
          nameAndPathCreate={nameAndPathCreate}
          data={[seectionSectionsPageList, seectionArticlesPageList, seectionDiagramsPageList, seectionFilesPageList]}
        />
      ) : null}
      <ListTollbar
        setFormData={setFormData}
        toggle_width_article={toggle_width_article}
        RigtMenuShow={RigtMenuShow}
        setRightMenuShow={setRightMenuShow}
        getLeftArr={getLeftArr}
        setHistoryPopup={setHistoryPopup}
        itsArticleHistory={itsArticleHistory}
        articleHistory={articleHistory}
        editOpen={editOpen}
        GoArticleToRedact={GoArticleToRedact}
        redArticle={redArticle}
        setRedArticle={setRedArticle}
        setArticleText={setArticleText}
        setArticleData={setArticleData}
        ArticleData={ArticleData}
        articleText={articleText}
        logged={logged}
        setLogged={setLogged}
        onRedact={onRedact}
        post={post}
        Refresh={Refresh}
        getSections={getSections}
        setpopupActive={setpopupActive}
      />
    </div>
  );
}
export default MainWidget;

import React, { useState, useEffect, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
// import config from "../../../config/config";
import "./WikiArticteEditor.css";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Jwt_proxy from "../../API/Api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ArticleToolbar from "../articleToolbar/articleToolbar";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Draw from "../Pages/drawPage";

function Example({
  articleText,
  setTreeUserSection,
  ArticleData,
  firstArticleLoad,
  setHistoryArticleData,
  setFirstArticleLoad,
  setArticleData,
  addArticleText,
  pidChanged,
  setArticleText,
  seteditOpen,
  nameAndPathCreate,
  treeUserSection,
  edit,
  toggleSelectArticle,
  GoArticleToRedact,
  BodyTypeCheck,
  setRedArticle,
  setItsarticleHistory,
  toggleEdit,
  getSections,
  redArticle,
  getLeftArr,
  editOpen,
  itsArticleHistory,
  onRedact,
  red,
  setPidChanged,
  setArticleHistory,
  setSeectionArticlesPageList,
  setHistoryPopup,
  getHist,
  setSecUndefined,
  toggleSelect,
  setNameAndPathUpdate,
  nameAndPathUpdate,
  getThreePathData,
  parentData,
  setParentData,
  setArticleWiew,
  selected,
  setSelected,
  getParentId,
}) {
  const { Id, file_id } = useParams();
  let [Sel, setSel] = useState(null);
  const onValueChange = (e) => {
    setSel((Sel = e.target.value));

    if (window.getSelection()) {
      let select = window.getSelection();
      if (select !== null) {
        let ret = `${Sel}{${select.toString()}`;
        addArticleText(articleText.replace(select, ret));
      }
    }
  };
  const navigate = useNavigate();
  async function get_file_for_redact() {
    let ip =
      localStorage.getItem("server_ip") + "/api/wiki/files/get_file_for_redact";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: file_id }),
    });
    if (res.ok) {
      let r = await res.json();
      setArticleData((ArticleData) => ({
        ...ArticleData,
        body_type: 3,
        abstruct: r.description,
        name: r.name,
        title_font_color: r.title_font_color,
        code: r.code,
      }));
      setFileId(r.file_id);
      setBodType(3);
    }
  }

  function getChange(e) {
    setArticleData((ArticleData) => ({
      ...ArticleData,
      [e.target.name]: e.target.value,
    }));
  }
  function get_selected_text(d) {
    if (window.getSelection()) {
      let select = window.getSelection();
      let ret = "//section{" + select.toString() + "/LaTeX}";
      let newText = articleText.replace(select, ret);
      addArticleText(newText);
    }
  }

  const [bodyType, setBodType] = useState(BodyTypeCheck(ArticleData));
  const drawioRef = useRef(null);
  useEffect(() => {
    if (firstArticleLoad === true) {
      navigate("/wiki");
      return;
    } else {
      if (Id !== undefined && !firstArticleLoad && bodyType !== 4) {
        Jwt_proxy(() => GoArticleToRedact(Id, setBodType));
      } else if (file_id !== undefined && firstArticleLoad === false) {
        Jwt_proxy(get_file_for_redact);
        setBodType(3);
      } else if (bodyType === 4 && firstArticleLoad === false) {
        Jwt_proxy(getRedactDiagramm);
        setBodType(4);
      } else {
        setRedArticle(false);
        setArticleData((ArticleData) => ({
          ...ArticleData,
          id: null,
          name: null,
          create_date: new Date(),
          author: localStorage.getItem("username"),
        }));
        setArticleText("");
      }
      toggleEdit(true);
      setItsarticleHistory(false);
    }
  }, [Id]);

  useEffect(() => {
    setArticleData((ArticleData) => ({
      ...ArticleData,
      abstruct: null,
      wiki_article_type_id: 1,
      create_date: new Date(),
      code: null,
      title_font_color: null,
    }));
    dtyperef.current = 1;
    coderef.current = null;
    fontcolorref.current = null;
  }, []);

  const onChange = React.useCallback(
    (value, viewUpdate) => {
      if (bodyType === 1) {
        let splitStrings = value.match(/.{1,1500}/g);
        let finalString = splitStrings.join("\n");
        setArticleText(finalString);
        addArticleText(finalString);
      }
      setArticleText(value);
      addArticleText(value);
    },
    [articleText]
  );

  let [onFileChande, setOnFileChange] = useState();

  function getFileFromInput(e) {
    setOnFileChange((onFileChande = e.target.files[0]));
  }

  async function SetImage() {
    let ip = localStorage.getItem("server_ip") + "/files/new_file";

    let token = localStorage.getItem("token");
    const formData = new FormData();
    let file_type;
    if (bodyType === 3) {
      file_type = 1;
    } else {
      file_type = 2;
    }
    formData.append("file", onFileChande);
    formData.append("storage_type", "Wiki");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "x-token": token,
        file_type: file_type,
      },
      body: formData,
    });
    if (res.ok) {
      let r = await res.json();
      if (ArticleData.body_type === 2) {
        setArticleText(articleText + `<img src='${r.path}' alt='vvv'/>`);
        addArticleText(articleText + `<img src='${r.path}' alt='vvv'/>`);
      }
      if (ArticleData.body_type === 1) {
        setArticleText(articleText + "\\figure{storage/" + String(r.id) + "}");
        addArticleText(articleText + "\\figure{storage/" + String(r.id) + "}");
      }
      if (ArticleData.body_type === 3) {
        setFileId(r.id);
      }
    } else {
      alert("Нет");
    }
  }

  async function deleteFile() {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/wiki/file/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: file_id }),
    });

    if (res.ok) {
      navigate("/wiki/section/" + ArticleData.section_id);
      alert("Файл удален");
      Jwt_proxy(() => getLeftArr(ArticleData.section_id));
    } else {
      alert(
        "У вас нет прав на создание/редактирование статей в данном разделе"
      );
    }
  }

  async function deleteDiagram() {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/wiki/diagram/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: Id }),
    });

    if (res.ok) {
      navigate("/wiki/section/" + ArticleData.section_id);
      alert("Диаграма удалена");
      Jwt_proxy(() => getLeftArr(ArticleData.section_id));
    } else {
      alert(
        "У вас нет прав на создание/редактирование статей в данном разделе"
      );
    }
  }
  const EditoeCheck = () => {
    if (edit === undefined) {
      return (
        <div
          style={{
            display: "flex",
            width: "20%",
            justifyContent: "space-between",
            margin: "2% 0",
          }}
        >
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              checked={bodyType === 2 ? "checked" : ""}
              onChange={() => {
                setBodType(2);
                setArticleData((ArticleData) => ({
                  ...ArticleData,
                  body_type: 2,
                }));
              }}
              name="HTML"
            />
            <span>HTML</span>
          </div>
          <div style={{ display: "flex" }}>
            {" "}
            <input
              type="checkbox"
              checked={bodyType === 1 ? "checked" : ""}
              name="Tex"
              onChange={() => {
                setBodType(1);
                setArticleData((ArticleData) => ({
                  ...ArticleData,
                  body_type: 1,
                }));
              }}
            />
            <span>LaTex</span>
          </div>
          <div style={{ display: "flex" }}>
            {" "}
            <input
              type="checkbox"
              checked={bodyType === 3 ? "checked" : ""}
              name="Tex"
              onChange={() => {
                setBodType(3);
                setArticleData((ArticleData) => ({
                  ...ArticleData,
                  body_type: 3,
                }));
              }}
            />
            <span>Файл</span>
          </div>
          <div style={{ display: "flex" }}>
            <input
              type="checkbox"
              checked={bodyType === 4 ? "checked" : ""}
              onChange={() => {
                setBodType(4);
                setArticleData((ArticleData) => ({
                  ...ArticleData,
                  body_type: 4,
                }));
              }}
              name="Диаграма"
            />
            <span>Диаграма</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const colorSelect = (e) => {
    setSel(e.target.value);
    if (window.getSelection()) {
      let select = window.getSelection();
      if (select !== null) {
        let ret = `<p style={{color: "${
          e.target.value
        }"}}>${select.toString()}</p>`;
        let ch = `${select.toString()}`;
        let newText = articleText.replace(ch, ret);

        addArticleText(newText);
        setArticleText(newText);
      }
    }
  };
  const colref = useRef();
  const dtyperef = useRef();
  const coderef = useRef();
  const fontcolorref = useRef();

  let colrinput = (
    <div
      style={{
        width: "98%",
        borderTop: "1px solid gray",
        backgroundColor: "white",
        padding: "10px",
      }}
    >
      <input
        type="color"
        ref={colref}
        className="colorToolbar"
        onChange={colorSelect}
      />
      <button onClick={() => colref.current.click()}>Выбрать цвет</button>
    </div>
  );
  const [imgData, setImgData] = useState("");

  const sectionRef = useRef("");
  const descriptionRef = useRef("");

  const sectionOldRef = useRef("");
  async function getRedactDiagramm() {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");
    let res = await fetch(ip + "/api/wiki/diagram/get", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: JSON.stringify({ id: Id, red: true }),
    });
    if (res.ok) {
      let r = await res.json();
      setArticleData((ArticleData) => ({
        ...ArticleData,
        section_id: r.section_id,
        name: r.name,
        description: r.description,
        title_font_color: r.title_font_color,
        code: r.code,
      }));
      setImgData(r.diagram_data);
      drawioRef.current = r.diagram_data;
      sectionRef.current = r.section_id;
      descriptionRef.current = r.description;
      coderef.current = r.code;
      fontcolorref.current = r.title_font_color;
      sectionOldRef.current = r.section_id;
    } else if (res.status === 444) {
      alert("У вас нет прав на редактирование диаграмм в данном разделе");
      navigate("/wiki/diagram/" + Id);
    }
  }
  const exportD = () => {
    if (diagramRef.current) {
      diagramRef.current.exportDiagram({
        format: "xmlsvg",
      });
    }
  };
  const [general, setIsGeneral] = useState(true);

  const diagramRef = useRef(null);
  const [fileId, setFileId] = useState(null);
  const [fullscreen, setFullscreen] = useState(false);
  return (
    <div className="Editor col">
      <div className="Editor">
        {!firstArticleLoad ? (
          <ArticleToolbar
            treeUserSection={treeUserSection}
            deleteDiagram={deleteDiagram}
            deleteFile={deleteFile}
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            exportD={exportD}
            fileId={fileId}
            file_id={file_id}
            firstArticleLoad={firstArticleLoad}
            setFirstArticleLoad={setFirstArticleLoad}
            toggleSelectArticle={toggleSelectArticle}
            red={red}
            setPidChanged={setPidChanged}
            pidChanged={pidChanged}
            expanded_toolbar={true}
            getSections={getSections}
            redArticle={redArticle}
            ArticleData={ArticleData}
            getLeftArr={getLeftArr}
            setArticleData={setArticleData}
            editOpen={editOpen}
            itsArticleHistory={itsArticleHistory}
            setRedArticle={setRedArticle}
            onRedact={onRedact}
            GoArticleToRedact={GoArticleToRedact}
            setArticleHistory={setArticleHistory}
            setSeectionArticlesPageList={setSeectionArticlesPageList}
            setHistoryPopup={setHistoryPopup}
            getHist={getHist}
            setSecUndefined={setSecUndefined}
            toggleSelect={toggleSelect}
            setNameAndPathUpdate={setNameAndPathUpdate}
            nameAndPathUpdate={nameAndPathUpdate}
            getThreePathData={getThreePathData}
            parentData={parentData}
            setParentData={setParentData}
            setArticleWiew={setArticleWiew}
            selected={selected}
            setSelected={setSelected}
            getParentId={getParentId}
            setHistoryArticleData={setHistoryArticleData}
            setTreeUserSection={setTreeUserSection}
          />
        ) : null}
        <div className="editorToolbar">
          <EditoeCheck />
          <div className="articleName">
            <span>Название</span>
            <input
              type="text"
              name="name"
              value={ArticleData.name}
              className="nameInput"
              onChange={getChange}
            />
          </div>
          {bodyType === 4 ? (
            <div style={{ zIndex: 10, width: "33%", display: "flex" }}>
              <span
                style={{ display: "block", width: "50%", textAlign: "center" }}
                className={general ? "ListBtn selectedList" : "ListBtn"}
                onClick={() => setIsGeneral(true)}
              >
                Общее
              </span>
              <span
                style={{ display: "block", width: "50%", textAlign: "center" }}
                className={!general ? "ListBtn selectedList" : "ListBtn"}
                onClick={() => setIsGeneral(false)}
              >
                Диаграмма
              </span>
            </div>
          ) : null}
          <div
            style={{
              display: general ? "block" : "none",
              width: "100%",
              minHeight: "20vh",
              border: bodyType === 4 ? "1px solid black" : "none",
              position: bodyType === 4 ? "relative" : "",
              top: -1,
            }}
          >
            <div
              style={{
                display: bodyType !== 4 ? "flex" : "block",
                width: bodyType !== 4 ? "" : "40%",
                alignItems: "center",
                height: bodyType !== 4 ? "40px" : "auto",
                margin: "0 0 20px 0",
              }}
            >
              <div
                style={{
                  margin: bodyType == 4 ? "7px 0" : "0",
                  maxHeight: "22px",
                  display: "flex",
                  alignItems: "center",

                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    margin: bodyType == 4 ? "0 5px 0 5px" : "0 7px 0 0",
                  }}
                >
                  Код
                </span>
                <input
                  maxlength="30"
                  placeholder="Код"
                  name="code"
                  value={ArticleData.code}
                  onChange={(e) => {
                    getChange(e);
                    coderef.current = e.target.value;
                  }}
                  type="text"
                />
              </div>
              <div
                style={{
                  margin: bodyType == 4 ? "15px 0" : "auto",
                  maxHeight: "22px",
                  display: bodyType !== 3 ? "flex" : "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ margin: "0 5px 0 5px" }}>Тип статьи</span>
                <select
                  name="wiki_article_type_id"
                  style={{ width: "18vw" }}
                  onChange={(e) => {
                    getChange(e);
                    dtyperef.current = e.target.value;
                  }}
                  value={ArticleData.wiki_article_type_id}
                  className={"editorToolbar select"}
                >
                  <option value={1}>Статья общего характера</option>
                  <option value={2}>Бизнес процесс</option>
                  <option value={3}>Положение</option>
                </select>
              </div>

              <div
                style={{
                  margin: bodyType == 4 ? "15px 0" : "auto",
                  maxHeight: "22px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ margin: "0 5px 0 5px" }}>Цвет в дереве</span>
                <input
                  className=""
                  name="title_font_color"
                  value={ArticleData.title_font_color}
                  onChange={(e) => {
                    getChange(e);
                    fontcolorref.current = e.target.value;
                  }}
                  type="color"
                />
              </div>
            </div>

            <div
              className="articleName"
              style={{
                alignItems: "baseline",
                display: general ? "flex" : "none",
                margin: bodyType == 4 ? "7px" : "0",
                width: bodyType == 4 ? "98%" : "",
              }}
            >
              <span>Описание</span>
              <textarea
                onChange={getChange}
                name="abstruct"
                value={ArticleData.abstruct}
                id=""
                cols="30"
                rows="5"
                className="nameInput"
                style={{
                  resize: "none",
                  width: bodyType == 4 ? "88%" : "",
                  marginLeft: bodyType == 4 ? "0px" : "",
                }}
              ></textarea>
            </div>
          </div>
         
            <div
              style={
                !fullscreen
                  ? { width: "100%", paddingTop: "20px", display:general?'none':'block' }
                  : {
                      position: "fixed",
                      zIndex: "15",
                      minWidth: "100vw",
                      minHeight: "100vh",
                      top: 0,
                      left: 0,
                      display:general?'none':'block'
                    }
              }
            >
              <div
                style={{
                  display: fullscreen ? "flex" : "none",
                  backgroundColor: "white",
                  alignItems: "center",
                }}
              >
                <button
                  className="articleToolbarBtn"
                  onClick={() => setFullscreen(!fullscreen)}
                  title="Стандартный размер"
                >
                  <img src="/static/img/fscreen.svg" alt="" />
                </button>
                <input
                  type="text"
                  name="name"
                  style={{ width: "90%", fontSize: "20px" }}
                  value={ArticleData.name}
                  className="nameInput"
                  onChange={getChange}
                />
              </div>
              <Draw
                fullscreen={fullscreen}
                dtyperef={dtyperef}
                coderef={coderef}
                fontcolorref={fontcolorref}
                descriptionRef={descriptionRef}
                sectionRef={sectionRef}
                sectionOldRef={sectionOldRef}
                Id={Id}
                setFirstArticleLoad={setFirstArticleLoad}
                diagramRef={diagramRef}
                drawioRef={drawioRef}
                imgData={imgData}
                getRedactDiagramm={getRedactDiagramm}
                ArticleData={ArticleData}
                seteditOpen={seteditOpen}
                getLeftArr={getLeftArr}
                getSections={getSections}
                nameAndPathCreate={nameAndPathCreate}
              />
            </div>
        

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent:
                fileId !== null && bodyType === 3
                  ? "space-between"
                  : "flex-end",
            }}
          >
            {fileId !== null && bodyType === 3 ? fileId : null}
            <div
              className="input__wrapper"
              style={{
                width: "40%",
                display: bodyType !== 4 ? "flex" : "none",
              }}
            >
              <label for="input__file" className="inputFileLabel">
                {/* <span className="input__file-button-text">Загрузить файл</span> */}
              </label>
              <div>
                <label for="input__file" class="custom-file-upload">
                  {bodyType === 3 ? "Выбрать файл" : "Выбрать изображение"}
                </label>
                <input
                  onChange={getFileFromInput}
                  name="file"
                  type="file"
                  id="input__file"
                  className="input__file"
                  accept={
                    bodyType === 3
                      ? ".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf, application/pdf"
                      : "image/*"
                  }
                />
              </div>
              <button
                className="wikiButton"
                onClick={() => Jwt_proxy(SetImage)}
              >
                Загрузить
              </button>
            </div>
          </div>
          {bodyType === 1 ? (
            <div className="toolbarBtns">
              <select onChange={onValueChange}>
                <option value={null}>Обычный</option>
                <option value="h1">Заголовок 1</option>
                <option value="h2">Заголовок 2</option>
                <option value="h3">Заголовок 3</option>
                <option value="h4">Заголовок 4</option>
                <option value="h5">Заголовок 5</option>
                <option value="h6">Заголовок 6</option>
                <option value="h7">Заголовок 7</option>
                <option value="h8">Заголовок 8</option>
              </select>
              <button type="button" onClick={() => get_selected_text("B")}>
                <b>B</b>
              </button>
              <button type="button" onClick={() => get_selected_text("I")}>
                <i>I</i>
              </button>
              <button type="button" onClick={() => get_selected_text("S")}>
                <strike>S</strike>
              </button>
              <button type="button" onClick={() => get_selected_text("INC")}>
                <ins>P</ins>
              </button>
              <input
                className="colorinput"
                type="color"
                onChange={onValueChange}
              />
              <span className="delimiter">|</span>
              <button type="button" onClick={() => get_selected_text("ul>li")}>
                ...
              </button>
              <button type="button" onClick={() => get_selected_text("ol>li")}>
                123
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {bodyType === 1 ? (
          <CodeMirror
            value={articleText}
            height="100%"
            width="100%"
            onChange={onChange}
          />
        ) : bodyType === 2 ? (
          <>
            {/* {colrinput} */}
            <CKEditor
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "insertTable",
                  "mediaEmbed",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              editor={ClassicEditor}
              data={ArticleData.body}
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                addArticleText(data);
                setArticleText(data);
              }}
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
export default Example;

import SectionPageItem from "./sectionPageItem";

const SectionPage = ({
  data,
  getLeftArr,
  firstArticleLoad,
  setFirstArticleLoad,
  toggleSelect,
  getThreePathData,
  nameAndPathCreate,
  itemisfirst,
}) => {
  let first = "";
  if (itemisfirst) {
    first = "ПОСЛЕДНИЕ ДОБАВЛЕННЫЕ ";
  }
  
  return (
    <div className="SectionPageWrapper">
      <div className="sectionSections">
        <h2>{first}РАЗДЕЛЫ</h2>
        {data[0]
          ? [
              ...data[0].map((i) => (
                <SectionPageItem
                  setFirstArticleLoad={setFirstArticleLoad}
                  nameAndPathCreate={nameAndPathCreate}
                  getThreePathData={getThreePathData}
                  getLeftArr={getLeftArr}
                  toggleSelect={toggleSelect}
                  content_type="section"
                  data={i}
                />
              )),
            ]
          : null}
      </div>
      <div className="sectionArticles">
        <h2>{first}СТАТЬИ</h2>
        {data[1]
          ? [
              ...data[1].map((i) => (
                <SectionPageItem
                  firstArticleLoad={firstArticleLoad}
                  setFirstArticleLoad={setFirstArticleLoad}
                  getLeftArr={getLeftArr}
                  toggleSelect={toggleSelect}
                  content_type="article"
                  data={i}
                />
              )),
            ]
          : null}
          {data[2]
          ? [
              ...data[2].map((i) => (
                <SectionPageItem
                  firstArticleLoad={firstArticleLoad}
                  setFirstArticleLoad={setFirstArticleLoad}
                  getLeftArr={getLeftArr}
                  toggleSelect={toggleSelect}
                  content_type="diagram"
                  data={i}
                />
              )),
            ]
          : null}
          {data[3]
          ? [
              ...data[3].map((i) => (
                <SectionPageItem
                  firstArticleLoad={firstArticleLoad}
                  setFirstArticleLoad={setFirstArticleLoad}
                  getLeftArr={getLeftArr}
                  toggleSelect={toggleSelect}
                  content_type="file_mini"
                  data={i}
                />
              )),
            ]
          : null}
      </div>
    </div>
  );
};
export default SectionPage;

import React, { Component } from "react";
import "./Auth.css";
// import config from "../../config/config";
import { Navigate } from "react-router-dom";
class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      logged: localStorage.getItem("logged"),
      wrong: "",
    };
  }

  b64EncodeUnicode(str) {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode("0x" + p1);
        }
      )
    );
  }

  onValueChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name]: e.target.value,
    });
  };
  FetchServer = async () => {
    let auth = this.b64EncodeUnicode(
      `${this.state.username}:${this.state.password}`
    );
    try{
    let res = await fetch(
      localStorage.getItem("server_ip") + "/api/users/login",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Basic ${auth}`,
        },
      }
    );

    if (res.ok) {
      let result = await res.json();
     
        
        
        
      

      localStorage.setItem("token", result.access_token);
      localStorage.setItem("refresh_token", result.refresh_token);
      localStorage.setItem("logged", result.user_valid);
      this.setState({ logged: localStorage.getItem("logged") });
      localStorage.setItem("username", result.username);
      if (result.is_saler) {
        this.props.setLink("/opt");
        localStorage.setItem("link", "/opt");
      } else {
        localStorage.setItem("link", "/wiki");
        return this.props.setLink("/wiki");
      }
      
    } 
    else if (res.status == 403){
      this.setState({ wrong: "Неверный логин или пароль" });
    }
  }
    
    catch{
      this.setState({ wrong: "Ошибка сервера" });
    }
  };

  render() {
    let flag = this.props.link === "/login";

    if (this.state.logged === "true" && flag === false) {
      return <Navigate to={this.props.link} />;
    } else {
      return (
        <>
          <div className="AuthBody">
            <div className="flex-conteiner">
              <form
                className="login-form-wrapper"
                onSubmit={(e) => {
                  this.FetchServer();
                  e.preventDefault();
                }}
              >
                <div className="login-title">
                  <img
                    className="logo"
                    src="static/img/logo-mreason.svg"
                    alt=""
                  />
                </div>
                <div className="login-form">
                  <input
                    className="login-input"
                    onChange={this.onValueChange}
                    id="username"
                    type="text"
                    name="username"
                    placeholder="Логин"
                  ></input>
                  <input
                    className="login-input"
                    onChange={this.onValueChange}
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Пароль"
                  ></input>
                  <button className="login-btn" type="submit">
                    Вход
                  </button>
                  {<h4>{this.state.wrong}</h4>}
                </div>
              </form>
            </div>
          </div>
        </>
      );
    }
  }
}
export default Auth;
